import store from '../store/store'
import { repositoryClass } from '@/repositories/RepositoryClass'

function repo () {
  return repositoryClass('roster', `${store.getters['facility/facility'].id}/${store.getters['area/area'].slug}/rosters`)
}
export default {
  index (bidYear) {
    return repo().get('index', null, { bid_year: bidYear })
  },
  create (payload) {
    return repo().post(payload)
  },
  update (id, payload) {
    return repo().put(id, payload)
  },
  delete (id) {
    return repo().del(id)
  },
  get (rosterID) {
    return repo().get('getRoster', `${rosterID}`)
  },
  getMembers (rosterID) {
    return repo().get('getMembers', `members/${rosterID}`)
  },
  order (rosterID, payload) {
    return repo().put(null, { changes: payload }, 'orderRoster', `${rosterID}/order`, true)
  },
  orderTeams (rosterID, payload) {
    return repo().put(null, { changes: payload }, 'orderRosterTeam', `${rosterID}/orderTeams`, true)
  }
}
