import { repositoryClass } from '@/repositories/RepositoryClass'

function repo () {
  return repositoryClass('home', '')
}
export default {
  contact (email) {
    return repo().post({ email }, 'contact', 'contact')
  }
}
