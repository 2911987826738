import AreaRepository from './areaRepository'
import AuthRepository from './authRepository'
import BidRepository from './bidRepository'
import BidderRepository from './bidderRepository'
import ContentRepository from './contentRepository'
import CrewRepository from './crewRepository'
import FacilityRepository from './facilityRepository'
import HomeRepository from './homeRepository'
import LeaveRepository from './leaveRepository'
import LeaveTypeRepository from './leaveTypeRepository'
import LineRepository from './lineRepository'
import LineGroupRepository from './lineGroupRepository'
import MembersRepository from './memberRepository'
import MemberLeaveRepository from './memberLeaveRepository'
import MessageRepository from './messageRepository'
import RosterRepository from './rosterRepository'
import RosterTeamRepository from './rosterTeamRepository'
import RoundRepository from './roundRepository'

const repositories = {
  area: AreaRepository,
  auth: AuthRepository,
  bid: BidRepository,
  bidder: BidderRepository,
  content: ContentRepository,
  crew: CrewRepository,
  facilities: FacilityRepository,
  home: HomeRepository,
  leave: LeaveRepository,
  leavetype: LeaveTypeRepository,
  line: LineRepository,
  linegroup: LineGroupRepository,
  member: MembersRepository,
  memberleave: MemberLeaveRepository,
  message: MessageRepository,
  roster: RosterRepository,
  rosterteam: RosterTeamRepository,
  round: RoundRepository
}

export const RepositoryFactory = {
  get (name) {
    return repositories[name]
  }
}
