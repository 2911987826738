<template>
    <div>
        <area-form :show.sync="showForm"
                   v-model="Area"
                   :is-super="is('super')"
                   :creating="creating"
                   @saved="refresh"></area-form>

        <card>
            <h6 class="d-inline-block pt-3 text-primary">Manage Areas for {{ facility.name }}</h6>
            <n-button type="primary"
                      size="sm"
                      @click.native="showForm = true"
                      class="ml-5 float-right">
                <i class="fas fa-plus"></i> New Area
            </n-button>

            <table class="table results">
                <thead>
                <tr>
                    <td style="width: 150px">Name</td>
                    <td style="width: 150px">Slug</td>
                    <td>Slack</td>
                    <td style="width: 100px">Use Bid Aid</td>
                    <td style="width: 100px">Holiday Leave</td>
                    <td style="width: 70px">&nbsp;</td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(area) in areas" :key="area.id">
                    <td>{{ area.name }}</td>
                    <td class="font-italic font-weight-light">{{ area.slug | lowercase }}</td>
                    <td>
                        <span v-if="area.slack_webhook">
                            {{ area.slack_webhook }} / {{ area.slack_channel }}
                        </span>
                    </td>
                    <td class="text-center">
                        <i v-if="area.use_bid_aid" class="fas fa-check text-success"></i>
                        <i v-else class="fas fa-times text-danger"></i>
                    </td>
                    <td class="text-center">
                        <i v-if="area.subtract_holiday_leave" class="fas fa-check text-success"></i>
                        <i v-else class="fas fa-times text-danger"></i>
                    </td>
                    <td class="text-center">
                        <i class="fas fa-sync cursor-pointer mr-1" @click="resetArea(area)"></i>
                        <i class="fas fa-edit cursor-pointer mr-1" @click="editArea(area)"></i>
                        <i class="fas fa-trash text-danger cursor-pointer" @click="deleteArea(area)"></i>
                    </td>
                </tr>
                </tbody>
            </table>
        </card>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import AreaForm from './AreaForm'
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
const AreaRepository = RepositoryFactory.get('area')

export default {
  name: 'areas',
  components: {
    AreaForm
  },
  data () {
    return {
      Area: {},
      showForm: false,
      creating: true,
      showConfirmation: false
    }
  },
  methods: {
    init () {
      return {
        facility_id: this.facility.id,
        name: '',
        slug: '',
        tag: '',
        use_bid_aid: false,
        subtract_holiday_leave: false,
        slack_webhook: '',
        slack_channel: '',
        grace_hours: 0,
        accrual_slot_factor: 8
      }
    },
    editArea (area) {
      this.showForm = true
      this.creating = false
      this.Area = JSON.parse(JSON.stringify(area))
    },
    deleteArea (area) {
      this.$confirm('Are you sure you want to delete the ' + area.name + ' area?', 'Warning', {
        type: 'warning'
      }).then(() => {
        AreaRepository.delete(area.id)
          .then(() => {
            this.refresh(area)
          })
      })
    },
    refresh (area) {
      // Reload Areas
      this.$store.dispatch('area/fetchAreas')
        .then(() => {
          // If we just edited the current area, refresh the store
          if (area.id === this.area.id) {
            this.$store.dispatch('area/setArea', area.slug)
          }

          // Reset the area
          this.creating = true
          this.Area = this.init()
        })

      // Refresh bidders ## 220914 - Disabled; Pusher should remove the need for this - Triggering UI error
      // this.$store.dispatch('bidder/fetch')
    },
    resetArea (area) {
      this.$confirm('Are you sure you want to reset the ' + area.name + ' area?' +
        '<br><strong>This is VERY destructive, and will result in the loss of all bids, lines, and leave slots!</strong>', 'Warning', {
        type: 'warning',
        dangerouslyUseHTMLString: true
      }).then(() => {
        AreaRepository.reset(area)
          .then(() => {
            this.refresh(area)
          })
      })
    }
  },
  computed: {
    ...mapGetters({
      facility: 'facility/facility',
      areas: 'area/areas',
      area: 'area/area',
      is: 'auth/is'
    })
  },
  created () {
    this.Area = this.init()
  }
}
</script>
<style></style>
