import store from '../store/store'
import { repositoryClass } from './RepositoryClass'

function repo () {
  return repositoryClass('line', `${store.getters['facility/facility'].id}/${store.getters['area/area'].slug}/lines`)
}
export default {
  get () {
    return repo().get()
  },
  create (payload) {
    return repo().post(payload)
  },
  update (id, payload) {
    return repo().put(id, payload)
  },
  delete (id) {
    return repo().del(id)
  },
  linesByGroup (groupID) {
    return repo().get('linesByGroup', `linegroup/${groupID}`)
  },
  order (payload) {
    return repo().put(null, { changes: payload }, 'order', 'order', true)
  },
  duplicate (line, newLineNumber) {
    return repo().post({ newLineNumber }, 'duplicate', `${line.id}/duplicate`)
      .then(r => {
        repo().message('Line copied successfully.', 'success')
        return r
      })
  }
}
