import store from '../store/store'
import { repositoryClass } from './RepositoryClass'

function repo () {
  return repositoryClass('Line Group', `${store.getters['facility/facility'].id}/${store.getters['area/area'].slug}/linegroups`)
}
export default {
  index () {
    return repo().get('index')
  },
  get (bidYear, withInactive = false) {
    return repo().post({ bid_year: bidYear, withInactive }, 'groupsByYear', 'byYear')
  },
  create (payload) {
    return repo().post(payload)
  },
  update (id, payload) {
    return repo().put(id, payload)
  },
  delete (id) {
    return repo().del(id)
  },
  duplicate (groupID) {
    return repo().post(null, 'duplicate', `duplicate/${groupID}`)
      .then(r => {
        repo().message('Line Group copied successfully.', 'success')
        return r
      })
  },
  import (groupID, importGroupID) {
    return repo().post({ importGroupID }, 'import', `${groupID}/import`)
  }
}
