import store from '../store/store'
import { repositoryClass } from './RepositoryClass'

function repo () {
  return repositoryClass('leave', `${store.getters['facility/facility'].id}/${store.getters['area/area'].slug}/leave`)
}
export default {
  getCalendar (bidYear = null, start = null, end = null) {
    return repo().get('getCalendar', 'calendar', { bid_year: bidYear, start, end })
  },
  getSlots (bidYear, typeID = null, start = null, end = null) {
    return repo().get('getSlots', 'slots', { bid_year: bidYear, type_id: typeID, start, end })
  },
  saveSlots (payload) {
    return repo().post(payload, 'saveSlots', 'save-slots')
  },
  get () {
    return repo().get()
  },
  create (payload) {
    return repo().post(payload)
  },
  update (id, payload) {
    return repo().put(id, payload)
  },
  delete (id) {
    return repo().del(id)
  }
}
