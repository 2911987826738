import store from '../store/store'
import { repositoryClass } from '@/repositories/RepositoryClass'

function repo () {
  return repositoryClass('message', `${store.getters['facility/facility'].id}/messages`)
}
export default {
  index (bidYear, page) {
    // Handle pages
    const param = page !== 1 ? `?page=${page}` : ''

    return repo().get('index', `${param}`, { bid_year: bidYear })
  },
  get (messageID) {
    return repo().get('get', `${messageID}`)
  },
  create (payload) {
    return repo().post(payload)
  },
  update (id, payload) {
    return repo().put(id, payload)
  },
  delete (id) {
    return repo().del(id)
  },
  search (bidYear, payload) {
    return repo().post({ bid_year: bidYear, criteria: payload }, 'search', 'search')
  }
}
