import store from '../store/store'
import { repositoryClass } from '@/repositories/RepositoryClass'

function repo () {
  return repositoryClass('Roster Team', `${store.getters['facility/facility'].id}/${store.getters['area/area'].slug}/rosterteams`)
}
export default {
  index () {
    return repo().get()
  },
  create (payload) {
    return repo().post(payload)
  },
  update (id, payload) {
    return repo().put(id, payload)
  },
  delete (id) {
    return repo().del(id)
  }
}
